<template>
  <div class="coupon">
    <div class="coupontype" v-if="type != 15">
      <div class="left">
        <div v-if="couponList.couponType == 1" style="color: #ffffff; font-size: 30px; font-weight: 400">
          <i style="font-size: 12px">￥</i>{{ couponList.value }}
        </div>
        <div v-else-if="couponList.couponType == 2" style="color: #ffffff; font-size: 15px; font-weight: 400">
          <i style="font-size: 30px">{{ couponList.value >= 60 ? valuechange : couponList.value }}</i>
          {{ couponList.value >= 60 ? "小时" : "分钟" }}
        </div>
        <div v-else-if="couponList.couponType == 3" style="color: #ffffff; font-size: 15px; font-weight: 400">
          <i style="font-size: 30px">{{ (100 - couponList.value) / 10 }}</i>
          折
        </div>
        <div v-else style="color: #ffffff; font-size: 25px; font-weight: 400">全免</div>
      </div>
      <div class="right">
        <div class="top-cotent">
          <div style="font-size: 15px; font-weight: 400; letter-spacing: 1px; color: #333333">
            {{ couponName }}
          </div>
          <div class="rule" @click="handlerule">
            规则
            <div class="el-icon-arrow-right"></div>
          </div>
        </div>
        <div style="font-size: 12px; font-weight: 400; color: #a3a3a3">使用地址:{{ itemName }}</div>
        <div style="font-size: 12px; font-weight: 400; color: #a3a3a3">发券商户：{{ businessName }}</div>
      </div>
    </div>
    <div style="font-size: 25px; font-weight: 400; color: #333333">请输入车牌号码领取</div>
    <div class="input-plate-wrap">
      <div class="plate-for" v-for="(item, index) in keyBoard.kbLenght" :key="index" @click="getmenuItem(index)">
        <div
          class="plate-item"
          v-if="index != keyBoard.kbLenght - 1"
          :class="{ active: index === keyBoard.indexNum }"
          @click="carPlateInput(index)"
        >
          <i v-if="keyBoard.value[index] == ''" style="color: #a3a3a3; font-size: 18px">-</i>
          {{ keyBoard.value[index] || "" }}
        </div>
        <!-- 新能源多一位车牌 -->
        <div
          class="plate-item"
          style="border: 1px dashed #00deb9"
          v-if="index == keyBoard.kbLenght - 1"
          :class="{ active: index === keyBoard.indexNum }"
          @click="carPlateInput(index)"
        >
          <i
            v-if="keyBoard.value[keyBoard.kbLenght - 1] == '' && keyBoard.value.length == 8"
            style="color: #a3a3a3; font-size: 18px"
            >-</i
          >
          {{ keyBoard.value[index] }}
        </div>
      </div>
    </div>
    <!-- 车牌颜色 -->
    <div class="plateColor" v-if="type == 15">
      <div
        v-for="(item, index) in plateColorList"
        :key="index"
        :class="{
          'active-click': nowIndex == index,
          'plateColor-item': true,
        }"
        @click="handleplateColor(index)"
      >
        <div v-if="nowIndex == index" style="color: white; font-size: 16px; margin-right: 4px">
          <van-icon name="success" />
        </div>
        {{ item }}
      </div>
    </div>
    <el-button class="receive" @click="handlereceive" :loading="loading"> 领取 </el-button>
    <div>
      <PlateInput
        :kbShow="keyBoard.isShow"
        :kbIndex="keyBoard.indexNum"
        :kbType="keyBoard.length"
        @childValue="keyboardValue"
        @childClose="keyboardClose"
        @childIndex="keyboardIndex"
        @childDel="keyboardDel"
      />
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import PlateInput from "./components/plateInput";

export default {
  name: "Coupon",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    PlateInput,
  },
  data() {
    return {
      loading: false,
      keyBoard: {
        //键盘
        isShow: false,
        kbLenght: 8, // 车牌输入框长度
        indexNum: " ", // 点击车牌设置焦点的下标
        value: ["", "", "", "", "", "", "", ""], // 键盘值
        berthId: null, // 泊位id
        roadId: null, // 路段id
      },
      couponList: {},
      couponName: localStorage.getItem("couponName"),
      itemName: localStorage.getItem("itemName"),
      businessName: localStorage.getItem("businessName"),
      type: localStorage.getItem("type"),
      // 车牌颜色
      plateColorList: ["蓝牌车", "黄牌车", "黑牌车", "白牌车", "绿牌车"],
      nowIndex: 0,
      // 车牌颜色选择
      plateColor: 1,
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  created() {
    this.couponList = JSON.parse(this.$route.query.couponDate);
  },
  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
      WeixinJSBridge.call("hideOptionMenu");
    });
  },
  computed: {
    valuechange() {
      return Math.floor(this.couponList.value / 60) + (this.couponList.value % 60) / 60;
    },
  },
  methods: {
    // 车牌颜色选择
    handleplateColor(index) {
      switch (index) {
        case 0:
          this.plateColor = 1;
          break;
        case 1:
          this.plateColor = 2;
          break;
        case 2:
          this.plateColor = 3;
          break;
        case 3:
          this.plateColor = 4;
          break;
        case 4:
          this.plateColor = 5;
          break;
      }
      this.nowIndex = index;
    },
    // 规则
    handlerule() {
      Dialog.alert({
        message: `1.所有的券互斥，每次按照时间顺序使用最早领取的那张     2.车在停车场外时如果领了多张优惠券，余下的可以下次使用，车在停车场内时如果领了多张优惠券，使用一张后余下的作废`,
      }).then(() => {
        // on close
      });
    },
    // 领取
    handlereceive() {
      this.loading = true;
      let { keyBoard, plateColor } = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let openId = localStorage.getItem("openId");
      let businessId = localStorage.getItem("businessId");
      let userId = localStorage.getItem("userId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let payMethod = alipayuserId ? 2 : 1;
      let { couponId, markType, mark } = this.couponList;
      let plateNo = "";
      if (keyBoard.value[6] !== "") {
        keyBoard.value.forEach((item) => {
          if (item !== "") {
            plateNo += item;
          }
        });
        if (this.type == 15) {
          this.$axios
            .post(`${this.domain}/api/month/appletSendCoupon`, {
              appId,
              openId: openId ? openId : alipayuserId,
              businessId,
              couponsId: couponId,
              plateNo,
              number: 1,
              plateColor,
              markType,
              markUuid: mark,
              number: 1,
            })
            .then((res) => {
              this.loading = false;
              if (res.data.code == 200) {
                this.$router.replace("/outpark/couponsucess");
              } else {
                Dialog.alert({
                  message: `${res.data.msg}`,
                }).then(() => {
                  // on close
                });
              }
            })
            .catch((e) => {
              alert(JSON.stringify(e));
            });
        } else {
          this.$axios
            .post(`${this.domain}/api/pay/mobileQRCouponMethod`, {
              appId,
              itemId,
              openId: openId ? openId : alipayuserId,
              userId,
              businessId,
              couponId,
              markType,
              mark,
              plateNo,
              payMethod,
            })
            .then((res) => {
              this.loading = false;
              if (res.data.code == 200) {
                if (res.data.data != null) {
                  if (openId) {
                    this.weixinPay(res.data.data);
                  }
                  if (alipayuserId) {
                    this.tradePay(res.data.data);
                  }
                } else {
                  this.$router.replace("/outpark/couponsucess");
                }
              } else {
                Dialog.alert({
                  message: `${res.data.msg}`,
                }).then(() => {
                  // on close
                });
              }
            });
        }
      } else {
        this.loading = false;
        Dialog.alert({
          message: `请正确输入车牌`,
        }).then(() => {
          // on close
        });
      }
    },
    //点击车牌输入框
    carPlateInput(res) {
      this.keyBoard.isShow = true; //显示键盘
      this.keyBoard.indexNum = res; //设置键盘焦点下标
    },
    //键盘点击返回的值
    keyboardValue(res) {
      this.keyBoard.value.splice(this.keyBoard.indexNum, 1, res);
      // this.$setObject(this.keyBoard.value, this.keyBoard.indexNum, res);
    },
    //删除键盘值
    keyboardDel(res) {
      this.keyBoard.value.splice(res, 1, "");
      // this.$setObject(this.keyBoard.value, res, "");
    },
    //关闭
    keyboardClose(res) {
      this.keyBoard.isShow = res;
      this.keyBoard.indexNum = ""; //关闭键盘键盘高亮取消
    },
    //车牌焦点下标
    keyboardIndex(res) {
      this.keyBoard.indexNum = res;
    },
    // 车牌下标展示
    getmenuItem(index) {},
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$router.replace("/outpark/couponsucess");
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          this.paid = false;
          alert("支付已取消，请重新扫码");
        } else {
          this.paid = false;
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      let that = this;
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else {
              that.paid = false;
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  position: relative;
  height: 812px;
  padding: 20px 15px;
  background: linear-gradient(rgba(248, 248, 248, 1) 0.263%, rgba(255, 255, 255, 1) 100%);

  .coupontype {
    display: flex;
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;

    .left {
      width: 80px;
      height: 80px;
      background-color: #ff9d4d;
      background: linear-gradient(60deg, #fed586 0%, #ff9d4d 100%);
      border-radius: 5px 0px 0px 5px;
      line-height: 80px;
      text-align: center;
    }

    .right {
      box-sizing: border-box;
      padding: 10px;
      width: 265px;
      height: 80px;
      background: rgb(255, 255, 255);
      border-radius: 0px 5px 5px 0px;
      line-height: 20px;

      .top-cotent {
        display: flex;
        justify-content: space-between;
        .rule {
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #a3a3a3;

          .ruleimg {
            margin-left: 5px;
            width: 5px;
            height: 9px;
            background-image: url("../../assets/coupon-.png");
            background-repeat: repeat;
            background-position: none;
          }
        }
      }
    }
  }

  .plateColor {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .plateColor-item {
      display: flex;
      align-items: bottom;
      padding: 4px 10px;
      text-align: center;
      font-size: 12px;
      color: #a3a3a3;
      border: 1px solid rgb(229, 229, 229);
      border-radius: 5px;
    }

    :nth-child(5) {
      background-color: #00deb9;
      color: white;
      margin-right: 0;
    }
  }

  .input-plate-wrap {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    .plate-for {
      position: relative;

      .plate-item {
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: center;
        flex: 1;
        margin: 0 3px;
        height: 50px;
        width: 36px;
        font-size: 26px;
        // border: 1px solid #bbe6fe;
        align-items: center;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
        border-radius: 5px;

        &.active {
          border: 1px solid #bc3c3c;
        }

        .icon-zhinengkeji-icon-11 {
          font-size: 26px;
          color: green;
        }
      }
    }

    .point {
      display: flex;
      width: 14px;
      height: 14px;
      border-radius: 14px;
      margin-top: 40px;
      background-color: #c3c2cb;
    }

    .icondiandongche {
      color: green;
      font-size: 20px;
    }
  }

  .receive {
    margin-top: 30px;
    width: 345px;
    height: 44px;
    background: rgb(35, 112, 255);
    box-shadow: 0px 2px 10px rgba(35, 112, 255, 0.4);
    border-radius: 22px;
    text-align: center;
    // line-height: 44px;
    font-size: 15px;
    color: #ffffff;
  }

  ::v-deep .el-button {
    line-height: normal;
  }
}

.active-click {
  color: white !important;
  background: rgb(35, 112, 255);
}
</style>
